import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SidePanel = ({title = "", visible = false, onclose = () => {}, ...rest}) => {
    return(
        <div className={`MySidePanelInner ${(visible) ? `MySidePanelInnerShow` : `MySidePanelInnerHide` }`}>
            <div className='MySidePanelInner-header'>
            <Grid container spacing={0}>
                <Grid item lg={11} xs={11} sm={10} md={10}>
                    <Typography variant='h6' color="primary" component="div" sx={{ fontSize:"18px", flexGrow: 1, fontWeight: "600", }}>
                        {title ?? ''}
                    </Typography>
                </Grid>
                <Grid item lg={1} xs={1} sm={2} md={2}>
                    {/* <center> */}
                    <IconButton aria-label="delete" onClick={onclose} sx={{background: "#ff000014", padding: "2px"}}>
                        <CloseIcon sx={{fontSize: "25px", color: "red"}} />
                    </IconButton>
                    {/* </center> */}
                </Grid>
            </Grid>
            </div>
            <div className="MySidePanelInner-content">
                {rest.children}
            </div>
        </div>
    )
}

export default SidePanel;
