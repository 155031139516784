import { legacy_createStore as  createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from 'redux-thunk';
import AuthReducer from "./reducers/AuthReducer";

//reducers

const store = combineReducers({
    auth: AuthReducer,
});

const middlewareStore = applyMiddleware (ReduxThunk)(createStore)
export default middlewareStore(store,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() );