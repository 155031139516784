import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import config from '../../core/config';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CarCarousel = ({ images = [], ...rest }) => {
    const navigate = useNavigate();

    const options = {
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    const handleImageClick = (item) => {
        window.location.href = `${config.uris.PUBLIC_IMAGE_URI}/${item.image}`;
    };
    

    return (
        <Grid container spacing={0}>
            <Grid item lg={12} xs={12} sm={12} md={12}>
                <OwlCarousel
                    className="owl-theme"
                    autoplay={true}
                    items={1}
                    loop
                    margin={10}
                    nav={true}
                    dots={false}
                    {...options}
                >
                    {
                        images.length !== 0 ? images.map((item, i) => (
                            <div key={i} className="item" onClick={() => handleImageClick(item)} style={{height:"250px"}}>
                                <img src={`${config.uris.PUBLIC_IMAGE_URI}/${item.image}`} alt={`car-image-${i}`} style={{height:"100%",width:"100%",objectFit:"cover"}}/>
                            </div>
                        )) : <></>
                    }
                </OwlCarousel>
            </Grid>
        </Grid>
    );
};

export default CarCarousel;
