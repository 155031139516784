import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const WebFooter = () => {
  return (
    <div className="my-footer" >
      <Grid container spacing={0.8}>
        {/* Section 1: BN Motors Info */}
        <Grid
          item
          lg={4}
          md={4}
          sx={{
            "@media (max-width: 590px)": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#fff",
              fontSize: "28px",
              fontWeight: "5000",
              marginBottom: "10px",
            }}
          >
            BN Motors
          </h1>
          <p style={{ color: "#fff", fontSize: "14px" }}>
            <strong>WE, BN&nbsp;Motors</strong>, Situated At Ranchi Road
            Manguria, Purulia, West Bengal Are One of The Most Trusted Second
            Hand Car Dealer In The City. We Are Leading Second Hand Car Dealer
            Where You Can Buy A&nbsp;Used Car Of Any Brand. Our Mission Is To
            Bring Joy &amp; Delight Into Car Buying &amp; Ownership. It&rsquo;s
            As Simple As That. We Believe in The Quality &amp; Dependability Of
            Our Used Cars. Keeping In Mind The Customer&rsquo;s Expectations And
            Concerns Our Expert Team Gets a Right Car For You.
          </p>
        </Grid>

        {/* Section 2: Quick Links */}
        <Grid
          item
          lg={3}
          md={3}
          sx={{
            marginLeft: "10px",
            "@media (max-width: 590px)": {
              display: "none",
            },
          }}
        >
          <h1
            style={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "5000",
              marginBottom: "10px",
            }}
          >
            Quick Link
          </h1>
          <ul className="my-quick-link">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/tandc">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/tandc">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
          </ul>
        </Grid>

        {/* Section 3: Contact Us */}
        <Grid item lg={4} md={4} sx={{
            marginLeft: "10px",
            "@media (max-width: 590px)": {
              display: "none",
            },
          }}>
          <h1
            style={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "5000",
              marginBottom: "10px",
            }}
          >
            Contact Us
          </h1>
          <ul className="my-quick-link" style={{ listStyleType: "none" }}>
            <li>
              <a href="javascript:void(0)">
                Address: Maguria, Ranchi Road, Purulia, 723101 - WB
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                Call Us:
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7710 222 444
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7710 222 666
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                Mail us: info@bnmotors.in | support@bnmotors.in
              </a>
            </li>
          </ul>
        </Grid>

        {/* Footer Section */}
        <Grid
          item
          lg={12}
          md={12}
          sx={{
            background: "#022d5a",
            borderRadius: "12px",
            "@media (max-width: 590px)": {
              display: "none",
            },
          }}
        >
          <center>
            <p style={{ color: "#fff" }}>
              Copyrights &copy; {new Date().getFullYear()}{" "}
              <span style={{ color: "cyan", fontWeight: "600" }}>
                BN MOTORS
              </span>{" "}
              All Rights Reserved.
            </p>
            <p style={{ color: "#fff" }}>
            Technology support By -{" "}
              <span style={{ color: "cyan", fontWeight: "600" }}>
                Siance Software Pvt Ltd
              </span>{" "}
            </p>
          </center>
        </Grid>
      </Grid>
    </div>
  );
};

export default WebFooter;
