import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  Stack,
  Avatar,
  Chip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/images/logo.jpeg";
import config from "../../core/config";
import { useDispatch, useSelector } from "react-redux";
import { set_login as setLogin } from "../../store/actions/AuthAction";
import axios from "axios";
import DefaultAvatar from "../../assets/images/avatar.png";
import { useNavigate } from "react-router-dom";
import { BottomNav } from "../../core/BottomNav";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [nameErr, setNameErr] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const authData = useSelector((state) => state.auth);

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // console.log("token : ", token);

    if (authData.isAuth !== true) {
        navigate("/login", { replace: true });
    }
}, [navigate]);

  const handleUpdateProfile = async () => {
    if (userData.name == "") {
      setNameErr(true);
    } else {
      setNameErr(false);
      setIsLoading(true);
      console.log("userData : ", userData);
      const data = {
        name: userData.name,
      };

      await axios
        .post(`${config.rootUrl}/${config.uris.AUTH_USER_UPDATE}`, data)
        .then(({ data }) => {
          console.log("data : ", data);
          if (data.status) {
            dispatch(setLogin(data.data));
            setIsLoading(false);
             navigate('/');
            // window.location.reload();
          } else {
            alert(data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <div style={{ padding: "15% 15px" }}>
        <Stack
          spacing={1.5}
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          sx={{
            color: config.colors.primary.main,
            padding: "5px 0px",
          }}
        >
          <Avatar
            alt="satyajit-kumar"
            src={DefaultAvatar}
            sx={{ width: "100px", height: "100px" }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            {user.name ?? ""}
          </Typography>
          <Chip
            sx={{ height: "22px" }}
            label={
              <p
                style={{
                  color: "white",
                  padding: "0px 10px",
                  fontSize: "16px",
                }}
              >
                User
              </p>
            }
            color="secondary"
          />
        </Stack>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box autoComplete="off">
              <FormControl sx={{ margin: "15px 0px" }} fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  value={userData.name ?? user.name ?? ""}
                  onChange={(e) =>
                    setUserData({ ...userData, name: e.target.value })
                  }
                />

                {nameErr && (
                  <FormHelperText
                    sx={{ color: config.colors.red }}
                    id="phone-helper"
                  >
                    Name is Required!
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ margin: "15px 0px" }} fullWidth>
                {isLoading ? (
                  <LoadingButton loading variant="outlined">
                    Update
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateProfile}
                  >
                    Update
                  </Button>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </div>
      <BottomNav />
    </div>
  );
};

export default Profile;
