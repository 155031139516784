import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

function AppLoader({ visible = false, ...rest }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={visible}
      {...rest}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default AppLoader;
