import React from 'react';
import { Card, CardContent, CardActionArea } from '@mui/material';

const MyCard = ({style ={}, padding = "2px 5px", ...rest}) => {
    return (
        <Card className="my-card" style={style}>
            <CardActionArea className="my-card-inner">
            <CardContent sx={{padding: padding}}>
                {rest.children}
            </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default MyCard;