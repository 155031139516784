import React, { Fragment, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Grid,
  Box,
  Typography,
  Rating,
  Modal,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  FormHelperText,
} from "@mui/material";
import {
  Search as SearchIcon,
  DirectionsCarFilled as DirectionsCarFilledIcon,
  WhatsApp as WhatsAppIcon,
  PhoneInTalk as PhoneInTalkIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

import config from "../core/config";
import CarTile from "../screens/Car/CarTile";
import SidePanel from "../components/SlidePanel";
import CarCarousel from "../screens/Car/CarCarousel";
import AppLoader from "../components/AppLoader";
import WebFooter from "../components/WebFooter";
import { useHistory } from "react-router-dom";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import Column from "../components/Column";
import CarSpec from "./Car/CarSpec"; 
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ListItemText from "@mui/material/ListItemText";
import { Navbar } from "../core/Navbar";
import { BottomNav } from "../core/BottomNav";

// Main Home Component
const Home = () => {
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [visibleItemTile, setVisibleItemTile] = useState(false);
  const [rating, setRating] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [wpModalOpen, setWpModalOpen] = useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [isWebsite, setIsWebsite] = useState(false);

  const [visibleCars, setVisibleCars] = useState([]);
  const [homeData, setHomeData] = useState({
    cars: 0,
    trucks: 0,
    others: 0,
  });
  const [rowCount, setRowCount] = useState(5);
  const [rawData, setRawData] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchHomData = async () => {
    setIsLoading(true);
    await axios
      .get(`${config.rootUrl}/${config.uris.homedata}`)
      .then(({ data }) => {
        if (data.status) {
          setHomeData(data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const fetchProducts = async ( val = 0, page = 1) => {
    setIsLoading(true);
    var token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await axios.get(`${config.rootUrl}/${config.uris.getAllProducts}?count=${rowCount}&cat=${val+4}&page=${page}`)
    .then(({ data }) => {
        console.log("Product : ",data)
        if (data.status) {
            setRawData(data.data);
                setProducts(prev => [...prev, ...data.data.data]);
        } 
        setIsLoading(false);
    }).catch(err => {
        console.error(err);
        setIsLoading(false);
    });
    
}

  const increaseViews = async (id = null) => {
    var myUri = "";
    if (authData.isAuth) {
      var token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      myUri = `${config.rootUrl}/auth/${config.uris.triggreViews}`;
    } else {
      myUri = `${config.rootUrl}/${config.uris.triggreViews}`;
    }
    await axios
      .post(myUri, {
        product_id: id,
      })
      .then(({ data }) => {
        if (data.status) {
          console.log(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCallModalOpen = () => setCallModalOpen(true);
  const handleCallModalClose = () => setCallModalOpen(false);

  const handleWpModalOpen = () => setWpModalOpen(true);
  const handleWpModalClose = () => setWpModalOpen(false);

  const handlePriceModalOpen = () => {
    authData.isAuth ? setPriceModalOpen(true) : navigate("/login");
  };
  const handlePriceModalClose = () => setPriceModalOpen(false);

  const handleTabChange = (event, newValue) => {
    setProducts([]); 
    fetchProducts(newValue); 
    setTabValue(newValue); 
  };
  

  const handleSidePanelClose = () => {
    setSelectedProduct(null);
    setVisibleItemTile(false);
  };

  const carSpecsList = [
    {
      title: selectedProduct?.registration_authority,
      subtitle: "Registration Authority",
      icon: (
        <PersonPinCircleIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/registration-authority`,
    },
    {
      title: selectedProduct?.manufacture_date,
      subtitle: "Manufacturer Date",
      icon: (
        <HandymanIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/manufacture-date`,
    },
    {
      title: selectedProduct?.vechile_location,
      subtitle: "Vehicle Location",
      icon: (
        <LocationOnIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/location`,
    },
    {
      title: selectedProduct?.insurance,
      subtitle: "Insurance",
      icon: (
        <CreditScoreIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/insurance`,
    },
    {
      title: selectedProduct?.kilometre_drive,
      subtitle: "KM Drive",
      icon: (
        <TimeToLeaveIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/km-drive`,
    },
    {
      title: selectedProduct?.fitness,
      subtitle: "Fitness",
      icon: (
        <FitnessCenterIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/fitness`,
    },
    {
      title: selectedProduct?.rc_or_ca,
      subtitle: "RC/CA",
      icon: (
        <HandymanIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/rc-ca`,
    },
    {
      title: selectedProduct?.year,
      subtitle: "Year",
      icon: (
        <AccessTimeIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/year`,
    },
    {
      title: selectedProduct?.tax,
      subtitle: "Tax",
      icon: (
        <PriceCheckIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/tax`,
    },
    {
      title: selectedProduct?.hp,
      subtitle: "HP",
      icon: (
        <PriceCheckIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/hp`,
    },
    {
      title: selectedProduct?.owner,
      subtitle: "Owner",
      icon: (
        <PriceCheckIcon
          sx={{ color: config.colors.primary.main, fontSize: "14px" }}
        />
      ),
      link: `/car/${selectedProduct?.id}/owner`,
    },
  ];
  useEffect(() => {
    if (window.screen.width > 1000) {
      console.log("Large Screen");
      setRowCount(10);
    }
    fetchHomData();
    fetchProducts();
    var temp = products.filter((item) => {
      var searchKey = item.name + item.price + item.price + item.year;
      searchKey = searchKey.toLowerCase();
      return searchKey.indexOf(searchText.toLowerCase()) !== -1;
    });
    if (window.screen.width > 1000) {
      setIsWebsite(true);
    } else {
      setIsWebsite(false);
    }
  }, [searchText]);

  return (
    <div>
        <Navbar/>
      <div
        style={{
          marginTop: "63px",
          position: "fixed",
          top: "2px",
          width: "100%",
          height: "108px",
          zIndex: "800",
        }}
      >
        <div
          style={{
            background: config.colors.primary.main,
            padding: "8px 8px 0px 8px",
          }}
        >
          <Column>
            <Grid container justifyContent="center" spacing={0}>
              <Grid item xs={10} sm={10} md={4} lg={4}>
                <input
                  onClick={() => navigate("/search")}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  type="text"
                  readOnly
                  className="form-control app-input"
                  placeholder="Search ..."
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: "-2px",
                    marginTop: "5px",
                    width: "98%",
                    minWidth: "57px",
                    height: "40px",
                    color: "#fff",
                    borderRadius: "12px",
                  }}
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", marginTop: "-5px" }}>
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => handleTabChange(e, newValue)} // Use handleTabChange
                centered
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab
                  sx={{ color: "white", padding: "5px", minHeight: "50px" }}
                  iconPosition="start"
                  icon={<DirectionsCarFilledIcon sx={{ fontSize: "15px" }} />}
                  label={`${homeData.all?.label ?? ""}(${
                    homeData.all?.counts ?? "0"
                  })`}
                />
                <Tab
                  sx={{ color: "white", padding: "5px", minHeight: "50px" }}
                  iconPosition="start"
                  icon={<DirectionsCarFilledIcon sx={{ fontSize: "15px" }} />}
                  label={`${homeData.public_place?.label ?? ""}(${
                    homeData.public_place?.counts ?? "0"
                  })`}
                />
              </Tabs>
            </Box>
          </Column>
        </div>
      </div>
      <div id="popularCars">
        <InfiniteScroll
          dataLength={products.length}
          next={() => {
            fetchProducts(tabValue, rawData.current_page + 1);
          }}
          hasMore={true}
          loader={<AppLoader visibal={isLoading} />}
        >
          <Grid container spacing={0.9}>
            {products.map((item, i) => (
              <Grid key={i} item xs={12} sm={12} md={4} lg={3}>
                <CarTile
                  data={item}
                  onpress={() => {
                    setSelectedProduct(item);
                    setVisibleItemTile(true);
                    increaseViews(item.id);
                    // handleItemTileAction(item)
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </div>
      <SidePanel
        title={selectedProduct != null && selectedProduct.name}
        visible={visibleItemTile}
        onclose={handleSidePanelClose}
      >
        {selectedProduct != null && (
          <>
            <div>
              <CarCarousel images={selectedProduct.images} />
            </div>
            <div className="px-15">
              <Grid container spacing={0.5}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h3"
                    color={config.colors.primary.main}
                    sx={{ fontSize: "25px", fontWeight: "600" }}
                  >
                    {selectedProduct.name}
                  </Typography>
                  <p
                    style={{
                      color: "#333333",
                      fontWeight: "600",
                      fontSize: "13px",
                      marginTop: "4px",
                    }}
                  >
                    Added No: {selectedProduct.added_on}
                  </p>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography
                    variant="h6"
                    color={config.colors.black}
                    sx={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Specifications
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={6} sm={6}></Grid>
                {carSpecsList.map((item, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={12}>
                      <CarSpec
                        type="simple"
                        title={item.title}
                        subtitle={item.subtitle}
                        icon={item.icon}
                        isIcon={true}
                      />
                    </Grid>
                  );
                })}

                <Grid item xs={12} sm={12}>
                  <br />
                  <Typography
                    variant="h6"
                    color={config.colors.black}
                    sx={{ fontSize: "19px", fontWeight: "600" }}
                  >
                    Description
                  </Typography>
                  <p style={{ fontSize: "14px", color: "#333333" }}>
                    {selectedProduct.description}
                  </p>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <br />
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <center>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ borderRadius: "30px", width: "100%" }}
                          onClick={handleCallModalOpen}
                        >
                          Contact Saler
                        </Button>
                      </center>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <center>
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: "30px",
                            background: "#008069",
                            width: "100%",
                          }}
                          onClick={handleWpModalOpen}
                        >
                          WhatsApp
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                  <br />
                </Grid>
              </Grid>
              <div
                style={{
                  marginTop: "20px",
                  background: config.colors.accent.light,
                  borderRadius: "44px",
                  padding: "12px 1px",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-around"
                  alignItems="center"
                  alignContent="space-between"
                >
                  <Grid item xs={6} sm={6} lg={4} md={4}>
                    <p
                      style={{
                        fontSize: "25px",
                        fontWeight: "600",
                        color: config.colors.black,
                        margin: "12px 28px",
                      }}
                    >
                      {selectedProduct.price}
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={4} md={4}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handlePriceModalOpen}
                      sx={{
                        borderRadius: "50px",
                        padding: "12px 22px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                      startIcon={<CurrencyRupeeIcon sx={{}} />}
                    >
                      Your Price
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <br />
              <MyModalWp
                modalOpen={wpModalOpen}
                handleModalClose={handleWpModalClose}
              />
              <MyModalCall
                modalOpen={callModalOpen}
                handleModalClose={handleCallModalClose}
              />
              <MyModalPrice
                product={selectedProduct}
                modalOpen={priceModalOpen}
                handleModalClose={handlePriceModalClose}
              />
            </div>
          </>
        )}
      </SidePanel>
      <WebFooter />
      <BottomNav/>
    </div>
  );
};

const MyModalWp = ({
  modalOpen = false,
  handleModalClose = () => {},
  ...rest
}) => {
  const contactLinksWp = [
    { type: "whatsapp", label: "+91 7710222444", number: "+917710222444" },
    { type: "whatsapp", label: "+91 7710222666", number: "+917710222666" },
    // { type: "whatsapp", label: "+91 7710 177 177", number: "+917710177177" },
    // { type: "whatsapp", label: "+91 9732 777 888", number: "+919732777888" },
  ];

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="budget-modal">
        <ContactOption links={contactLinksWp} />
      </Box>
    </Modal>
  );
};

const MyModalCall = ({
  modalOpen = false,
  handleModalClose = () => {},
  ...rest
}) => {
  const contactLinksCall = [
    { type: "call", label: "+91-7710 222 444", number: "+917710222444" },
    { type: "call", label: "+91-7710 222 666", number: "+917710222666" },
  ];

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="budget-modal">
        <ContactOption links={contactLinksCall} />
      </Box>
    </Modal>
  );
};

const MyModalPrice = ({
  product = {},
  modalOpen = false,
  handleModalClose = () => {},
  ...rest
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="budget-modal">
        <SendPrice product={product} handleModalClose={handleModalClose} />
      </Box>
    </Modal>
  );
};

const ContactOption = ({ links = [], ...rest }) => {
  return (
    <>
      <Typography variant="h5">Contact</Typography>
      <List dense={true}>
        {links.map((item, i) => {
          return item.type == "call" ? (
            <Fragment key={i}>
              <Divider />
              <ListItem sx={{ padding: "0px 0px" }}>
                <ListItemButton
                  sx={{ padding: "4px 0px" }}
                  onClick={() => {
                    window.open(`tel:${item.number}`, "_self");
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: config.colors.accent.light }}>
                      <PhoneInTalkIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        color={config.colors.black}
                      >
                        <b>{item.label} </b>
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ) : (
            <Fragment key={i}>
              <Divider />
              <ListItem sx={{ padding: "0px 0px" }}>
                <ListItemButton
                  sx={{ padding: "4px 0px" }}
                  onClick={() => {
                    // console.log(`Opening WhatsApp for number: ${item.number}`);
                    window.open(`https://wa.me/${item.number}`, "_self");
                    // window.open(`https://api.whatsapp.com/send?phone=+91${'9732777888'}&text=${"Hello, I am Satya i just want info about this car!"}`, '_self');
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#4caf5024" }}>
                      <WhatsAppIcon sx={{ color: "#4caf50" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        color={config.colors.black}
                      >
                        <b>{item.label}</b>
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </>
  );
};

const SendPrice = ({ product = {}, handleModalClose = () => {}, ...rest }) => {
  const [message, setMessage] = useState();
  const [messageErr, setMessageErr] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendQuotation = async () => {
    if (message == " ") {
      setMessageErr(true);
    } else {
      setIsLoading(true);
      setMessageErr(false);

      const data = {
        message: message,
        product_id: product.id,
      };
      var token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(`${config.rootUrl}/${config.uris.sendQuotation}`, data)
        .then(({ data }) => {
          if (data.status) {
            setIsLoading(false);
            alert(data.message);
            handleModalClose();
          } else {
            alert(data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Send Your Price
      </Typography>
      <br />
      <textarea
        onChange={(e) => setMessage(e.target.value)}
        rows="5"
        className="my-textarea"
        placeholder="Write something ..."
      ></textarea>
      {messageErr && (
        <FormHelperText sx={{ color: config.colors.red }} id="phone-helper">
          Please enter something!
        </FormHelperText>
      )}
      {isLoading ? (
        <LoadingButton loading variant="outlined">
          Send
        </LoadingButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSendQuotation}
          sx={{
            marginTop: "10px",
            width: "100%",
            color: "#fff",
            borderRadius: "50px",
            padding: "8px 22px",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          Send
        </Button>
      )}
    </>
  );
};

export default Home;
