import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Drawer,
  Box,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FcAbout } from "react-icons/fc";
import { MdPrivacyTip } from "react-icons/md";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ViewListIcon from "@mui/icons-material/ViewList";
import FaceIcon from "@mui/icons-material/Face";
import LogoutIcon from "@mui/icons-material/Logout";
import GavelIcon from "@mui/icons-material/Gavel";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "./config";
import DefaultAvatar from "../assets/images/avatar.png";
import '../../src/App.css'
import { make_logout as makeLogout } from '../store/actions/AuthAction';

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [homeData, setHomeData] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isWebsite, setIsWebsite] = useState(false);

  const handleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  const fetchHomeData = async () => {
    try {
      const { data } = await axios.get(`${config.rootUrl}/app-alert`);
      if (data.status) {
        setHomeData(data.data);
      }
    } catch (err) {
      console.error("Error fetching home data:", err);
    }
  };

  useEffect(() => {
    fetchHomeData();
    setIsWebsite(window.innerWidth > 1000);
  }, []);

  const MyDrawer = ({ open = false, onClose = () => {} }) => (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <MyDrawerItems onClose={onClose} />
    </Drawer>
  );

  const MyDrawerItems = ({ onClose = () => {} }) => {
    const handleLogout = () => {
      if (window.confirm("Are you sure you want to logout?")) {
        dispatch(makeLogout());
      }
      onClose();
    };

    const handleNavigation = (path) => {
      navigate(path);
      onClose();
    };

    const AuthList = [
      { label: "Home", icon: <HomeIcon />, onClick: () => handleNavigation("/") },
      // { label: "My Quotation", icon: <ViewListIcon />, onClick: () => alert("Feature not implemented yet") },
      { label: "Profile", icon: <FaceIcon />, onClick: () => handleNavigation("/profile") },
      { label: "About", icon: <FcAbout />, onClick: () => handleNavigation("/about") },
      { label: "Logout", icon: <LogoutIcon />, onClick: handleLogout },
      { label: "Terms & Conditions", icon: <GavelIcon />, onClick: () => handleNavigation("/tandc") },
      { label: "Privacy Policy", icon: <MdPrivacyTip />, onClick: () => handleNavigation("/tandc") },
    ];

     const GuestList = [
       { label: "Login", icon: <FaceIcon />, onClick: () => handleNavigation("/login") },
     ];

    return (
      <Box sx={{ width: 280 }}>
        {authData.isAuth && (
          <Stack
            spacing={2}
            alignItems="center"
            sx={{ backgroundColor: config.colors.primary.main, color: "white", p: 2 }}
          >
            <Avatar
              alt="User Avatar"
              src={authData.user?.image ? `${config.uris.IMAGE_URI}/${authData.user.image}` : DefaultAvatar}
              sx={{ width: 100, height: 100 }}
            />
            <Typography variant="h6">{authData.user?.name || "Guest"}</Typography>
            <Chip label="User" color="secondary" />
          </Stack>
        )}
        <List>
          {/* {(authData.isAuth ? AuthList : GuestList).map((item, index) => ( */}
          {!authData.isAuth && GuestList.map((item, index) => (
          <ListItem key={index} disablePadding>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
            ))}
          {AuthList.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <div>
      <AppBar position="fixed" elevation={1}>
        <Toolbar style={{minHeight:"64px"}}>
            <IconButton edge="start" color="inherit" onClick={handleDrawer}>
              <MenuIcon style={{fontSize:"35px"}}/>
            </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: authData.isAuth ? "left" : "center" }}>
            BN MOTORS
          </Typography>
          {authData.isAuth && <Avatar src={DefaultAvatar} onClick={()=>navigate("/profile")}/>}
        </Toolbar>
      </AppBar>
      {homeData && (
        <Box className="floating-alert" sx={{ top: location.pathname === "/" ? 165 : "auto" }}>
          <Typography variant="body1" component="marquee">
            {homeData.message}
          </Typography>
        </Box>
      )}
      <MyDrawer open={isOpenDrawer} onClose={handleDrawer} />
    </div>
  );
};

export default Navbar;
