import React from 'react';

const Row = ({ style = {}, className = "", ...rest }) => {
    return (
      <div className={`app-row ${className}`} style={style}>
        {rest.children}
      </div>
    )
}

export default Row;