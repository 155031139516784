import React from 'react';

const Column = ({ style = {}, className = "", ...rest }) => {
  return (
    <div className={`app-column ${className}`} style={style}>
      {rest.children}
    </div>
  )
}

export default Column;