import './App.css';
import {Routes, Route } from "react-router-dom"; // Use react-router-dom
import { BrowserRouter } from 'react-router-dom';
import Home from "./screens/Home";
import Login from '../src/screens/Auth/Login';
import Register from '../src/screens/Auth/Register';
import Profile from '../src/screens/Auth/Profile';
import Tandc from './screens/Tandc';
import About from './screens/About';
import Search from './screens/Search';
import PostCar from './screens/Car/PostCar';


function App() {
  return (
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tandc" element={<Tandc/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/search" element={<Search />} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/post" element={<PostCar />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/register' element={<Register/>} />
        </Routes>
      </div>
  );
}

export default App;
