import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Box, CardActionArea, Chip, Typography, Skeleton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import config from '../../core/config';
import CarDemo from '../../assets/images/car-wash.png';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LazyLoadImage from '../../components/LazyLoadImage';
import { height } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';  // Add Link for navigation

const CarTile = ({ data = {}, onpress = () => {}, ...rest }) => {
    const [img, setImg] = useState(CarDemo);
    const [values, setValues] = useState({});
    console.log("data : ",data)
    
    useEffect(() => {
      let imgTimeout = setTimeout(() => {
        setImg((data.images?.length > 0 && data.images[0].image) ? `${config.uris.PUBLIC_IMAGE_URI}/${data.images[0].image}` : CarDemo);
      }, 1000);

      let box = document.querySelector('.mycard');
      let width = box.offsetWidth;
      let height = box.offsetHeight;
      setValues({ height, width });

      return () => {
        clearTimeout(imgTimeout);
      };
    }, [data.images]);

    const getFuleType = (val) => {
      if (val === "1") {
        return "Petrol";
      } else if (val === "2") {
        return "Diesel";
      } else {
        return "CNG";
      }
    };

    return (
      <Card
        onClick={onpress}
        className={`mycard ${(data.is_booked > 0) && `mycardBooked`} ${(data.is_upcoming > 0) && `mycardUpcoming`}`}
        sx={{
          display: 'flex',
          borderRadius: "4px",
          boxShadow: "0 1px 5px 0 #00000042",
          height: "150px"
        }}
      >
        {/* Conditional Rendering for Skeleton Loader */}
        <CardMedia
          component="img"
          sx={{
            width: "129px",
            objectFit: "cover",
            height: "100%",
            filter: `${(data.is_booked > 0) ? 'contrast(0.5)' : ''}`,
          }}
          image={img}
          alt="Live from astergo"
          onError={() => setImg(CarDemo)}  // Fallback image if there's an error loading
        />
        <CardActionArea
          sx={data.is_booked ? 
            { backdropFilter: 'contrast(0.3)' } : 
            data.is_upcoming ? 
            { backdropFilter: 'contrast(0.3)', backgroundColor: "#bbffbc", filter: 'hue-rotate(348deg)' } 
            : {}}
            style={{backgroundColor: "white", height: "100%",}}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between",backgroundColor: "white"}}>
            <CardContent sx={{ padding: "8px 8px !important" }}>
              <h1 className='aster-car-box-title' style={{ color: config.colors.primary.main }}>
                {data.name}
              </h1>
              <p className='aster-car-box-footer'>
                ADDED ON : {data.added_on}
              </p>
              <div className='myPill' style={{ justifyContent: "flex-start" }}>
                <div><WatchLaterIcon sx={{ fontSize: "15px", marginTop: "5px" }} />&nbsp;</div>
                <div>Year - {data.year}</div>
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <div className='myPill'>
                  <div><LocationOnIcon sx={{ fontSize: "15px", marginTop: "5px" }} />&nbsp;</div>
                  <div>{data.vechile_location}</div>
                </div>
                <div className='myPill'>
                  <div><LocalGasStationIcon sx={{ fontSize: "15px", marginTop: "5px" }} />&nbsp;</div>
                  <div>{getFuleType(data.fuel_type) ?? 'NA'}</div>
                </div>
                <div className='myPill'>
                  <div><RemoveRedEyeIcon sx={{ fontSize: "15px", marginTop: "5px" }} />&nbsp;</div>
                  <div>{data.views.toString() ?? '0'}</div>
                </div>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 0, pt: 0 }}>
                <Typography variant='subtitle2' sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: config.colors.black
                }}>
                  {`₹${data.price.toString()}`}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    );
};

export default CarTile;
