import { Typography } from '@mui/material';
import React from 'react'
import { Navbar } from '../core/Navbar';
import { BottomNav } from '../core/BottomNav';

function Tandc() {
    return (
       <div>
        <Navbar/>
         <div style={{ marginTop: "60px" }}>
            <iframe style={{
              border: "none",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "65px",  
            }} src='https://bnmotors.in/privacy-policy.html'></iframe>
        </div>
        <BottomNav/>
       </div>
    )
}

export default Tandc;
