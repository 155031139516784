import React, { useState } from 'react';
import { Box, FormControl, InputLabel, OutlinedInput, TextField, InputAdornment, IconButton, Card, CardContent, Grid, Typography, Button, FormHelperText } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../assets/images/logo.jpeg';
import config from '../../core/config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { set_login as setLogin } from '../../store/actions/AuthAction';


function Register() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [fullname, setFullname] = useState('');
    const [fullnameErr, setFullnameErr] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneErr, setPhoneErr] = useState(false);
    // const [values, setValues] = React.useState({
    //     password: '',
    //     showPassword: false,
    // });
    // const [passwordErr, setPasswordErr] = useState(false);


    const handleRegister = async () => {
        if(fullname == '') {
            setFullnameErr(true);
        } else if (phone == '' && phone.length < 10){
            setPhoneErr(true);
        // } else if(values.password == '') {
        //     setPasswordErr(true);
        } else {
            setIsLoading(true);
            setFullnameErr(false);
            setPhoneErr(false);
            // setPasswordErr(false);
            
            const data = {
                name: fullname,
                phone: phone,
                // password :values.password
            };

            await axios.post(`${config.rootUrl}/${config.uris.REGISTER_URI}`, data)
            .then(({data}) => {
                console.log("data : ",data)
                // if(data.status){
                //     toast.error("The phone has already been taken.");
                // }
                if (data.status === true) {
                    // localStorage.setItem('token', data.token);
                    console.log("Don")
                    toast.success("User register successfully");
                    // dispatch(setLogin(data.data.user))
                    setIsLoading(false);
                    navigate('/login');

                } else {
                    toast.error("The phone has already been taken.");
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    // const handleClickShowPassword = () => {
    //     setValues({
    //         ...values,
    //         showPassword: !values.showPassword,
    //     });
    // };
    
    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    return (
        <div style={{marginTop: "30px"}}>
            <div style={{padding: "15% 15px"}}>
                <Grid container spacing={0.5} justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div style={{
                        }}>
                            <center>
                                <img src={Logo} width="100px" style={{
                                    boxShadow: "0 0 20px 0 #03a9f47a",
                                    borderRadius: "100%",
                                    marginBottom: "10px",
                                }} />
                                <Typography variant="h5" sx={{color: config.colors.primary.main, fontWeight: "600"}}>
                                    BN MOTORS
                                </Typography>
                            </center>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card sx={{marginBottom: "8px"}}>
                            <CardContent>
                                <Typography variant='h5'>Register</Typography>
                                <Box autoComplete="off">
                                    <FormControl sx={{margin: "10px 0px"}} fullWidth >
                                        <TextField id="outlined-basic" label="Full Name" variant="outlined"
                                            onChange={(e) => setFullname(e.target.value)}
                                            error={fullnameErr}
                                            value={fullname}
                                        />
                                        {
                                            (fullnameErr) 
                                            &&
                                            <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Please enter full name!</FormHelperText>
                                        }
                                    </FormControl>
                                    <FormControl sx={{margin: "10px 0px"}} fullWidth >
                                        <TextField id="outlined-basic" label="Phone Number" variant="outlined"
                                            onChange={(e) => setPhone(e.target.value)}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: "10", minLength: "10" }}
                                            error={phoneErr}
                                        />
                                        {
                                            (phoneErr) 
                                            &&
                                            <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Invalid phone number</FormHelperText>
                                        }
                                    </FormControl>
                                    {/* <FormControl sx={{margin: "15px 0px"}} fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={(e) => setValues({...values, password: e.target.value})}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        {
                                            (passwordErr) 
                                            &&
                                            <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Password Required</FormHelperText>
                                        }
                                    </FormControl> */}
                                    <FormControl sx={{margin: "10px 0px 0px 0px"}} fullWidth >
                                        {
                                            (isLoading) 
                                            ?
                                            <LoadingButton loading variant="outlined">
                                                Register
                                            </LoadingButton>
                                            :
                                            <Button variant='contained' color="primary"
                                                onClick={handleRegister}
                                            >Register</Button>

                                        }
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={12}>
                        <Button variant="text"  onClick={() => (window.location.href = "/login")}>Login Here</Button>   
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Register
