import React,{useState} from 'react';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import FaceIcon from '@mui/icons-material/Face';
import { useNavigate } from 'react-router-dom'; 

export const BottomNav = () => {
  const navigate = useNavigate(); 
  const [value, setValue] = useState(0);

  return (
    <div className="bottomNav">
      <Box sx={{}}>
        <BottomNavigation
          sx={{}}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue); 
            if (newValue === 0) {
              navigate('/');
            }
            if (newValue === 1) {
              navigate('/post'); 
            }
            if (newValue === 2) {
              navigate('/profile'); 
            }
          }}
        >
          <BottomNavigationAction
            onClick={() => { navigate('/'); }}
            sx={{ padding: "10px", minWidth: "70px" }}
            label="Home"
            icon={<HomeIcon sx={{ padding: "0px", fontSize: "25px", marginBottom: "5px" }} />}
          />
          <BottomNavigationAction
            sx={{ padding: "10px", minWidth: "70px" }}
            label="Post"
            icon={<AddToPhotosIcon sx={{ padding: "0px", fontSize: "25px", marginBottom: "5px" }} />}
          />
          <BottomNavigationAction
            sx={{ padding: "10px", minWidth: "70px" }}
            label="Profile"
            icon={<FaceIcon sx={{ padding: "0px", fontSize: "25px", marginBottom: "5px" }} />}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};
